<template>
<div class="mrecords">
    <div class="header">
        <a-icon class="back" @click="$router.go(-1)" type="left" />
        <span class="title">對衝單</span>
    </div>
    <div class="list">
        <div class="item" v-for="x,i in list" :key="'r'+i">
            <div class="flex top">
                <div class="id">#{{ x.id }}</div>
                <div class="time color-gray">{{x.created_at}}</div>
            </div>
            <div class="content">
                <div class="title">
                    <span class="amount" v-if="x.t==5||x.t==7">
                        {{ x.mix_pay_info[1] }}U / {{ x.mix_pay_info[0] }}{{ $t('budhi.groundUnit') }}
                    </span>
                </div>
                <div class="sm-amount">{{x.bct_amount/1000000}} {{$t('common.currency')}}</div>
                <div class="sm-amount">{{x.amount/1000000}} USDT</div>
            </div>
            <div class="pay-btn">
                <a-tag v-if="x.mix_pay_status==0" color="#2db7f5">
                    未支付
                </a-tag>
                <a-tag v-else color="#87d068">
                    已支付
                </a-tag>
            </div>

        </div>
    </div>
</div>
  
</template>
<script>
export default {
  name: "Mrecords",
  data() {
    return {
        loading:false,
        list:[]
    };
  },
  components:{
  },
  mounted(){
      this.getData(false);
  },
  methods: {
    getData(){
        this.loading = true;
        this.$http.call(this.$http.api.mrecords).then(res=>{
            console.log(res);
            this.loading = false;
            let resp = res.data;
            if(resp.code=='200'){
                this.list = resp.data;
            }else{
                this.$message.error(resp.data);
            } 
        },res=>{
            console.log('something goes wrong...');
            console.log(res.response);
            if(res.response.status == '401'){
                this.$store.commit('setToken','');
                this.$router.push({name:'login'});
            }
        });
    },
  },
};
</script>
<style lang="less">
.mrecords{
    padding: 30px 50px;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    .header {
        position: relative;
        width: 100%;
        color: #fff;
        .back {
            position: absolute;
            left: 0;
            font-size: 20PX;
        }
        .title {
        text-align: center;
        font-weight: bold;
        font-size: 16PX;
        }
    }
    .list{
        margin-top: 50px;
        .item{
            padding: 24px;
            border-radius: 7px;
            margin-bottom: 20px;
            background: #fff;
            position: relative;
            text-align: left;
            .top {
                padding-bottom: 15px;
                border-bottom: 1px solid #d8d8d8;
            }
            .content {
                padding: 36px 15px;
                .amount{
                    font-size: 50px;
                    font-weight: bold;
                }
                .sm-amount {
                    color: @gray;
                }
            }
            
            .pay-btn {
                position: absolute;
                bottom: 36px; right: 15px;
            }
        }
    }
}

</style>
